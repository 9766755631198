// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.share-select-header {
  font-size: 20px;
  padding: 0 10px;
  width: 100%;
  text-align: center;
}

.share-info {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 10px;
}

.cancel-share-modal-button {
  color: var(--ion-color-danger);
}

.share-select-button {
  width: 100%;
}

.color-black {
  color: var(--ion-color-primary-contrast);
}

.icon-share {
  font-size: 18px;
  padding-right: 7px;
}

.popover-info {
  --width: 80%;
  --backdrop-opacity: 50% ;
}

#info-pop {
  margin-bottom: -3px;
}

.help-icon {
  display: inline-block;
  background: var(--ion-color-secondary);
  color: var(--planeed-color-white);
  width: 25px;
  height: 25px;
  border-radius: 15px;
  font-size: 16px;
  padding-left: 11px;
  top: 5px;
  position: relative;
  margin-inline-start: 10px;
}
.help-icon:hover {
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
