import {EventEmitter, Injectable} from '@angular/core';
import {Share, ShareOptions} from "@capacitor/share";
import {ModalController, Platform} from "@ionic/angular";
import {ApiResponse, handleError} from "../utils";
import {getImageToShare, getShareLink, postTrackPost} from "../variables";
import {catchError} from "rxjs/operators";
import {GlobalService} from "../global/global.service";
import {TranslateService} from "@ngx-translate/core";
import {firstValueFrom, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ShareModalComponent} from "../../modal/share-modal/share-modal.component";
import {DeviceDetectorService} from "ngx-device-detector";
import {UserService} from "../user/user.service";
import {Directory, Filesystem} from "@capacitor/filesystem";
import {ShareSelectModalComponent} from "../../modal/share-select-modal/share-select-modal.component";

@Injectable({
  providedIn: 'root'
})
export class ShareService {
  public simpleHeader = {
    'content-type': 'application/json',
    'x-deviceId': ''
  }

  public sharedLinkCounter = new EventEmitter<number>;

  private shareModal: any;
  private shareSelectModal: any;
  private postId: string = '';
  private filePath: string = '';

  constructor(
    private platform: Platform,
    private globalService: GlobalService,
    private modalController: ModalController,
    private translate: TranslateService,
    public http: HttpClient,
    private deviceDetectService: DeviceDetectorService,
    private userService: UserService
  ) {
  }

  async share(id: string, title: string, description: string, shareUrl?: string, file?: string) {
    this.postId = id;
    const lang = localStorage.getItem('selectLanguage') || 'en';
    const data = lang === 'de' ? {options: ['spreadTextDE']} : {options: ['spreadTextEN']};

    this.userService.apiGeneralOptions(data).subscribe({
      next: async (res) => {
        const textToShare = res && res.data && res.data[0] && res.data[0].value && res.data[0].value !== '' ? res.data[0].value : description;
        if (shareUrl && shareUrl !== '') {
          if (navigator.canShare && this.deviceDetectService.isMobile() || this.platform.is('capacitor')) {
            await this.shareImageOrText(id, title, textToShare, shareUrl, file);
          } else {
            await navigator.clipboard.writeText(shareUrl);
            await this.openModal(shareUrl, id);
          }
        } else {
          this.apiGetLink(id).subscribe({
            next: async (result) => {
              if (result && result.status) {
                if (navigator.canShare && this.deviceDetectService.isMobile() || this.platform.is('capacitor')) {
                  await this.shareImageOrText(id, title, textToShare, result.data.shareLink, file);
                } else {
                  await navigator.clipboard.writeText(shareUrl);
                  await this.openModal(shareUrl, id);
                }
              } else {
                await this.globalService.presentToast(this.translate.instant('err_general'), 2000)
              }
            }
          });
        }
      },
    });

  }

  async fileUri(fileUrl: string): Promise<string> {
    const response = await fetch(fileUrl).then(r => r.blob());
    const base64Data = await this.blobToBase64(response);
    const base64String = base64Data.split(',')[1];

    const fileName = `temp_image_${Date.now()}.png`;
    const result = await Filesystem.writeFile({
      path: fileName,
      data: base64String,
      directory: Directory.Cache,  // Use cache directory
    });
    this.filePath = fileName;
    return result.uri;
  }

  async blobFile(fileUrl: string, title: string): Promise<File> {
    if (fileUrl && fileUrl !== '') {
      const defaultType = 'image/jpeg';
      const response = await fetch(fileUrl).then(r => r.blob());
      const fileName = response && response.type ? (title + '.' + response.type.split('/')[1]).replace(' ', '') : 'planeed-post.jpeg'
      return new File([response], fileName, {
        type: response.type || defaultType,
      });
    } else {
      return null;
    }
  }

  async openModal(link: string, postId: string) {
    this.postId = postId;
    this.shareModal = await this.modalController.create({
      component: ShareModalComponent,
      backdropDismiss: true,
      componentProps: {
        link: link,
        linkGotShared: this.sharedLinkCounter
      },
      mode: 'ios',
      id: 'small-modal',
    });

    await this.shareModal.present();
    await this.shareModal.onDidDismiss();
  }

  apiGetLink(id: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getShareLink + '/' + id, {headers: this.simpleHeader}).pipe(
      catchError(handleError)
    );
  }

  apiTrackShare() {
    const data = {
      postId: this.postId,
      referrer: this.globalService.lastRoute
    }
    return this.http.post<ApiResponse>(postTrackPost, data, {headers: this.simpleHeader}).pipe(
      catchError(handleError)
    );
  }

  apiGetImageToShare(id: string){
    return this.http.get<ApiResponse>(getImageToShare + '/' + id, {headers: this.simpleHeader}).pipe(
      catchError(handleError)
    );
  }


  async shareImageOrText(id: string, title: string, text: string, shareUrl?: string, fileUrl?: string) {
    const generatedFile = await firstValueFrom(this.apiGetImageToShare(id));
    const tempPath = generatedFile && generatedFile.data && generatedFile.data.image_path ? generatedFile.data.image_path : fileUrl;
    const path = tempPath.replace(/\\/g, '');
    const data = async (): Promise<ShareData> => {
      const fileBlob: File = await this.blobFile(path, title);
      return {
        files: fileBlob ? [fileBlob] : [],
        title: title,
        url: shareUrl
      }
    }

    const dataToShare: ShareData = await data();
    if (navigator.canShare && navigator.canShare(dataToShare) || this.platform.is('capacitor')) {
      this.shareSelectModal = await this.modalController.create({
        component: ShareSelectModalComponent,
        backdropDismiss: true,
        mode: 'ios',
        id: 'select-modal',
      });

      await this.shareSelectModal.present();
      const shareSelectData = await this.shareSelectModal.onDidDismiss();
      if (shareSelectData) {
        if(shareSelectData.data === 'text'){
          await this.shareNavigatorText(id, title, text, shareUrl);
        } else if(shareSelectData.data === 'image'){
          await this.shareNavigatorFile(id, title, text, shareUrl, path, dataToShare);
        }
      }
    } else {
      await this.shareNavigatorText(id, title, text, shareUrl);
    }
  }

  async shareNavigatorFile(id: string, title: string, text: string, shareUrl?: string, fileUrl?: string, dataToShare? : ShareData) {
    if (this.platform.is('capacitor')) {
      const shareOptions: ShareOptions = {
        title: title,
        text: shareUrl,
        files: await this.fileUri(fileUrl) ? [await this.fileUri(fileUrl)] : [],
        dialogTitle: this.translate.instant('lbl_share'),
      }
      await this.shareNative(shareOptions);

    } else {
      if (navigator.canShare && navigator.canShare(dataToShare) && this.deviceDetectService.isMobile()) {
        await this.shareNavigatorShort(dataToShare);
      } else {
        await navigator.clipboard.writeText(shareUrl);
        await this.openModal(shareUrl, id);
      }
    }
  }

  async shareNavigatorText(id: string, title: string, text: string, shareUrl?: string) {
    const data = (): ShareData => {
      return {
        title: title,
        text: text,
        url: shareUrl
      }
    }

    if (this.platform.is('capacitor')) {

      const shareOptions: ShareOptions = {
        title: title,
        text: text,
        url: shareUrl,
        dialogTitle: this.translate.instant('lbl_share'),
      }
      await this.shareNative(shareOptions);
    } else {
      if (navigator.canShare && navigator.canShare(data()) && this.deviceDetectService.isMobile()) {
        await this.shareNavigatorShort(data());
      } else {
        await navigator.clipboard.writeText(shareUrl);
        await this.openModal(shareUrl, id);
      }
    }

  }

  async shareNavigatorShort(data: any) {
    navigator.share(data).then(() => {
      this.sharedLinkCounter.emit()
    }).catch((e) => {
      console.log('SHARE ERROR', e)
    });
  }

  async shareNative(shareOptions: ShareOptions) {
    await Share.share(shareOptions).then(() => {
      this.apiTrackShare().subscribe({
        complete: () => {
          if (this.filePath !== '') {
            Filesystem.deleteFile({
              path: this.filePath,
              directory: Directory.Cache
            }).then(() => {
              this.filePath = '';
            });
          }
        }
      });
      this.sharedLinkCounter.emit()
    });
  }

  async blobToBase64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob); // Convert Blob to base64 string
    });
  }

}

