import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {UserService} from "../../services/user/user.service";

@Component({
  selector: 'app-share-select-modal',
  templateUrl: './share-select-modal.component.html',
  styleUrls: ['./share-select-modal.component.scss'],
})
export class ShareSelectModalComponent  implements OnInit {

  isOpen: boolean = false;
  infoPoUpImage: string = '';
  infoPoUpText: string = '';

  constructor(
    private modalController: ModalController,
    private userService: UserService
  ) { }

  ngOnInit() {}

  openInfoPopup() {
    this.isOpen = true;
    const lang = localStorage.getItem('selectLanguage') || 'de';

    if(lang === 'de'){
      const fields = {
        options: ['shareInfoIconTextDE', 'shareInfoIconImageDE']
      };
      this.userService.apiGeneralOptions(fields).subscribe({
        next:(res: any) => {
          if(res && res.data){
            res.data.forEach((val: any) => {
              if(val.slug === 'shareInfoIconTextDE'){
                this.infoPoUpText = val.value;
              }
              if(val.slug === 'shareInfoIconImageDE'){
                this.infoPoUpImage = val.value;
              }
            });
          }
        }
      });
    } else {
      const fields = {
        options: ['shareInfoIconTextEN', 'shareInfoIconImageEN']
      };
      this.userService.apiGeneralOptions(fields).subscribe({
        next:(res: any) => {
          if(res && res.data){
            res.data.forEach((val: any) => {
              if(val.slug === 'shareInfoIconTextEN'){
                this.infoPoUpText = val.value;
              }
              if(val.slug === 'shareInfoIconImageEN'){
                this.infoPoUpImage = val.value;
              }
            });
          }
        }
      });
    }

  }
  async closeModal(type: string){
    await this.modalController.dismiss(type);
  }
}
